/* DEV */
//import configDEV from "./apiConfigDEV";
//const configsOficial = configDEV;

/* QAS */
//import configQAS from "./apiConfigQAS";
//const configsOficial = configQAS;

/* PRD */
import configPRD from "./apiConfigPRD";
const configsOficial = configPRD;

export default configsOficial;