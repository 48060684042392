import { UserAgentApplication } from "msal";

export const msalConfig = {

    auth: {
		clientId: "b16f4166-5574-481b-a5a4-e8e65953cf2c", // ID de aplicación
		authority: "https://login.microsoftonline.com/53bb278c-7d4e-4a80-aa68-0900f41f7ce3", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
		redirectUri: "https://pmgdspboxdev.azurewebsites.net/",
    },

    cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }

};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {

   	scopes: ["User.Read", "openid", "profile"]

};
  
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {

    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
	
};

// --------------------

// const msalConfig = {
//     authority: apiConfig.microsoft_authority,
//     clientId: apiConfig.microsoft_idclient,
//     redirectUri: document.getElementById('root')!.baseURI,
// };

// export const msalAuth = new UserAgentApplication({
//     auth: msalConfig,
//     cache: {
//         cacheLocation: 'sessionStorage',
//         storeAuthStateInCookie: false
//     },
// });