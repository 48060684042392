import { IButtonStyles, PrimaryButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

function Notificaciones() {

	const redireccionar = useNavigate();

    const DUMMY = [
    "Lorem ipsum dolor sit amet.",
    
    ]

    const primaryButtonStyles: IButtonStyles = {

		//   fieldGroup: [{ borderColor: '#ced4da' }]// required && { borderTopColor: props.theme.semanticColors.errorText,},],
		root: { background: '#004983', borderColor: '#004983' },
		
	}

    return (
        <>
        {DUMMY.map((dato, index, array) => { 

            let normalItem = {display: "flex", alignItems: "center", justifyContent: "space-between", width: "300px", marginTop: "10px"}

            let ultimoItem = undefined;

            if(index === array.length - 1) ultimoItem = {display: "flex", alignItems: "center", justifyContent: "space-between", width: "300px", marginTop: "10px", marginBottom: "10px"};

            return(
                <div key={index} style={ultimoItem ? ultimoItem : normalItem}>
                    <p style={{ marginLeft: "5px"}}>{dato}</p>
                    <div style={{ marginRight: "5px"}}>
                        <PrimaryButton 
                        text="Ver"
                        styles={primaryButtonStyles}
                        onClick={() => redireccionar('/Dashboard/Formulario')}/>
                    </div>
                </div>
            )

        })}

        </>
    )
}

export default Notificaciones;