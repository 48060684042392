import React, { useEffect, useState } from 'react';
import CSS from './Navbar.module.css'
import Notificaciones from '../Notificaciones';
import Opciones from '../Opciones';
import Logo from '../../Recursos/newLogo.svg';
import Campana from '../../Recursos/bell.svg'
import User from '../../Recursos/user.svg'
import Opcion from '../../Recursos/more.svg'
import { useNavigate } from "react-router-dom";
import { IComboBoxOption, IComboBoxStyles,Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react';
import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const comboBoxStyles: Partial<IComboBoxStyles> = { root: {} };
const styleOpciones = mergeStyleSets({

    callout: {
        margin: '2px -14px',
    },

});
const styleNotificaciones = mergeStyleSets({

    callout: {
        margin: '2px -4px',
    },

});
const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'sticky'
    },
};
const nonShrinkingStackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',

        display: 'flex',
        height: 50,
        justifyContent: 'center',
        overflow: 'hidden',
        width: 500,
    },
};

// Tokens definition
const outerStackTokens: IStackTokens = { childrenGap: 5 };
const innerStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,

};
const stackStyles: IStackStyles = {
    root: {

        overflow: 'hidden',
        backgroundColor: "#f3f2f9"

    },
};
function Navbar() {

    const { nombreUsuario } = useSelector((state: any) => state.UsuarioPMGD);

    const [notificacion, setNotificacion] = useState<boolean>(false);

    const [opciones, setOpciones] = useState<boolean>(false);
    return (nombreUsuario&&
        <>
                <Stack horizontal styles={stackStyles} tokens={innerStackTokens}>
                    <Stack.Item grow styles={stackItemStyles}>
                        <a href='/'><img src={Logo} alt="Logo" style={{ width: "130px", marginLeft: "40px" }} /></a>
                    </Stack.Item>
                    <Stack.Item grow styles={stackItemStyles}>
                        {/* <ComboBox
                            defaultSelectedKey="C"
                            options={options}
                            styles={comboBoxStyles} /> */}
                    </Stack.Item>

                    <Stack.Item grow styles={stackItemStyles}>
                        <img src={Campana} alt="Campana" style={{ width: "25px", margin: "0px 20px" }} id="Campana" />
                        {notificacion && (
                            <Callout
                                role="notification"
                                className={styleNotificaciones.callout}
                                gapSpace={0}
                                target={`#Campana`}
                                onDismiss={() => setNotificacion(!notificacion)}
                                setInitialFocus>
                                <Notificaciones />
                            </Callout>
                        )}
                        <p style={{ color: "#004983", fontWeight: "700", textAlign: "center", margin: "0px 20px" }}>{nombreUsuario}</p>
                        <div style={{ cursor: "pointer" }} onClick={() => setOpciones(true)}>
                            <img src={Opcion} alt="Options" style={{ margin: "0px 20px" }} id="Opciones" />
                            {opciones && (
                                <Callout
                                    role="notification"
                                    className={styleOpciones.callout}
                                    gapSpace={0}
                                    target={`#Opciones`}
                                    onDismiss={() => setOpciones(!opciones)}
                                    setInitialFocus>
                                    <Opciones />
                                </Callout>
                            )}
                        </div>
                    </Stack.Item>
                </Stack>

                <Outlet />
    
          
        </>);
       
};

export default Navbar;