import { API_LISTASMAESTRAS } from "./constants";
import { AppThunkAction } from "../../ConfigRedux/index";
import { IProcesoPMGD } from "../../../Interfaces/IProcesoPMDG";
import { IListaMaestra } from "../../../Interfaces/IListaMaestra"
import { useDispatch, useSelector } from "react-redux";
import configsOficial from '../../AppConfig/apiConfigOficial';

interface ObtenerListasMaestras {
	type: typeof API_LISTASMAESTRAS;
	DataListaMaestra: IListaMaestra;
}

export type KnowActions = ObtenerListasMaestras;

export const ActionListaMaestra = {

	ObtenerListasMaestras: (): AppThunkAction<KnowActions> => async (dispatch) => {

		try {

			let json = [
				{ nombreMaestra: "pmgdAlimentadores" },
				{ nombreMaestra: "pmgdRegion" },
				{ nombreMaestra: "pmgdComuna" },
				{ nombreMaestra: "pmgdTipoForm" },
				{ nombreMaestra: "pmgdTecnologiaDelPMGD" },
				{ nombreMaestra: "pmgdSubestacion" },
				{ nombreMaestra: "pmgdSubalimentador" },
				{ nombreMaestra: "pmgdSolicitudAdmisibilidad" },
				{ nombreMaestra: "pmgdSistemaGeneracion" },
				{ nombreMaestra: "pmgdRecursoEnergeticoPrimario" },
				{ nombreMaestra: "PMGDPuntoConexionDeseado" },
				{ nombreMaestra: "pmgdEmpresa" },
				{ nombreMaestra: "pmgdNumeroUnidadesF3" },
				{ nombreMaestra: "pmgdTipoSolicitud" },
				{ nombreMaestra: "PoligonoLocalizacion" },
				{ nombreMaestra: "pmgdFormularioLlenadoPor3A" },
				{ nombreMaestra: "pmgdTipoGenerador" },
				{ nombreMaestra: "pmgdInterruptorAcopla" },
				{ nombreMaestra: "pmgdElaboradorEstudio" },
				{ nombreMaestra: "pmgdTipoZona" },
				{ nombreMaestra: "pmgdTipoDocumento" },
				{ nombreMaestra: "pmgdPulsosF3B" },
				{ nombreMaestra: "pmgdControlDesdeF3B" },
				{ nombreMaestra: "pmgdArmonicasAcuerdo" },
				{ nombreMaestra: "pmgdEstadoConformidadF8" },
				{ nombreMaestra: "pmgdDeclaracionHitos" },
				{ nombreMaestra: "pmgdMotivoActICC" },
				{ nombreMaestra: "pmgdDeclaracionInteresado" },
				{ nombreMaestra: "pmgdImpactoEnRed" },
				{ nombreMaestra: "pmgdNotificacionConexion" },
				{ nombreMaestra: "pmgdFormaNotificacion" },
				{ nombreMaestra: "pmgdGeneradorAsincF3A" },
				{ nombreMaestra: "pmgdInterruptorAcopla" },
				{ nombreMaestra: "pmgdInterruptorAcoplamiento" },
				{ nombreMaestra: "pmgdEmpresa" },
				{ nombreMaestra: "pmgdEstadoInterconex" },
				{ nombreMaestra: "pmgdFallaAtribuida" },
				{ nombreMaestra: "pmgdCausaleConexFalli" },
				{ nombreMaestra: "pmgdInformeCriteriosConec" }
				

			];

			const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(json) };
			
			//const response = await fetch('https://pmgdspboxdev-backend.azurewebsites.net/api/Maestra/GetAllMaestraSP', requestOptions)
			const response = await fetch(`${configsOficial.apiUri}/Maestra/GetAllMaestraSP`, requestOptions)

			const listasMaestras = await response.json();
			for (let i = 0; i < listasMaestras.length; i++) {
				const listaMaestra = listasMaestras[i]
				listaMaestra.Opciones = [];
				const itemsListaMaestra = listasMaestras[i].itemsMaestra

				for (let j = 0; j <= itemsListaMaestra.length; j++) {
					const itemListaMaestra = itemsListaMaestra[j]
					let opcion;
					if (itemListaMaestra !== undefined) {
						if (itemListaMaestra.IdReg_comuna !== undefined) {
							opcion = {
								key: itemListaMaestra.ID,
								text: itemListaMaestra.Title,
								data: itemListaMaestra.IdReg_comuna
							}
						} else if (itemListaMaestra.Alimentador !== undefined) {
							opcion = {
								key: itemListaMaestra.ID,
								text: itemListaMaestra.Title,
								data: itemListaMaestra.Alimentador
							}
						} else {
							opcion = {
								key: itemListaMaestra.ID,
								text: itemListaMaestra.Title,

							}

						}
						listaMaestra.Opciones.push(opcion);
					}
				}
			}
			dispatch({ type: API_LISTASMAESTRAS, DataListaMaestra: listasMaestras })
		} catch (error) {

			console.log(error);

		}

	},GetImagenFirma: async(correo:string)=>{
		const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(correo) };
		
		//const response = await fetch('https://pmgdspboxdev-backend.azurewebsites.net/api/Maestra/GetImagenFirma', requestOptions)
		const response = await fetch(`${configsOficial.apiUri}/Maestra/GetImagenFirma`, requestOptions)
		const imageBase64 = await response.text();
		return imageBase64;
	}

};