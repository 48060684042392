import { KnowActions } from './actions';
import { Action, Reducer } from 'redux';
import { IDataUsuario } from '../../../Interfaces/IDataUsuario';
import { API_LOGIN } from './constants';

export interface IUsuarioPMGDState {
    usuario: string,
    acceso: number | undefined,
    tokenPMGD: string,
    rut: string |null,
    tokenDeTerceros: string | null,
    messageError: string | null,
    estadoUsuario: string | number | null,
  

}

export const initialState: IUsuarioPMGDState = {
    usuario: "",
    acceso: undefined,
    tokenPMGD: "",
    rut:"",
    tokenDeTerceros: null,
    messageError: null,
    estadoUsuario: null,
    
};

//Reducer determines how the state should change after every action.
export const InformacionUsuarioReducer: Reducer<IUsuarioPMGDState> = (state: IUsuarioPMGDState = initialState, incomingAction: Action): IUsuarioPMGDState => {

	const action = incomingAction as KnowActions;

    switch (action.type) {

		case API_LOGIN:
            // return {...state, ...action.DataUsuario}
            //console.log(action.DataUsuario);
			return action.DataUsuario
		
		default:
			return state;

    }

};