import { ReactSession } from 'react-client-session';
interface ISeccionLocal{
    nombreUsuario:string | undefined,
    usuario:string| undefined,
    tokenPMGD:string | undefined,
    rut:string | undefined,
}
const useLocalStorage = (): ISeccionLocal=> {
    const localNombre = ReactSession.get("Nombre Usuario")
	const localUsuario = ReactSession.get("Usuario")
	const localToken = ReactSession.get("TokenPMGD")
    const localRUT= ReactSession.get("rut")
    if(localNombre === undefined || localUsuario === undefined || localToken === undefined || localRUT===undefined) return  { 
        nombreUsuario: undefined, 
        usuario: undefined, 
        tokenPMGD: undefined,
        rut:undefined
    }
    else return { 

        nombreUsuario: localNombre, 
        usuario: localUsuario, 
        tokenPMGD: localToken,
        rut:localRUT

    }

}

export default useLocalStorage