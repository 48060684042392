import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { ApplicationState, reducers } from '.';
import { createLogger } from 'redux-logger';

const AppStore = configureStore();

export default AppStore;

function configureStore(initialState?: ApplicationState) {

    // Permitir uso del Logger y ¿thunk? al ingresarlos como middleware.
    const loggerMiddleware = createLogger({ collapsed: true });
    const middleware = [thunk, loggerMiddleware ];

    // Esparcir los reducers en un objeto y permitir visualizar todos los reducers en el logger.
    //  Ej: {
    //      UserslistReducer: {...users}
    //  }
    const rootReducer = combineReducers({ ...reducers });

    // ¿Mejoras?, ¿windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__()?
    const enhancers : Array<any> = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    // Crear el store, se le entrega información de los reducers, initialState y middleware.
    return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}