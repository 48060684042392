import { KnowActions } from './actions';
import { Action, Reducer } from 'redux';
//import { IProcesoPMGD } from "../../../Interfaces/IProcesoPMDG";
import { API_LISTASMAESTRAS } from './constants';

export interface IListasMaestrasPMGDState {
	
	DataListaMaestra: any[];
}

export const initialState: IListasMaestrasPMGDState = {

	DataListaMaestra: [],
};

//Reducer determines how the state should change after every action.
export const ListasMaestrasReducer: Reducer<IListasMaestrasPMGDState> = (state: IListasMaestrasPMGDState = initialState, incomingAction: Action): IListasMaestrasPMGDState => {
	
	const action = incomingAction as KnowActions;

    switch (action.type) {
		
		case API_LISTASMAESTRAS:
			//console.log(action);
			return action.DataListaMaestra
			// return { ...state, procesosPMGDList: action.procesoPMGDList};

		default:
			return state;
    }
};