import ReactDOM from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Sitios/Login/Microsoft/authConfig";
import { Provider } from 'react-redux';
import AppStore from './GlobalStore/ConfigRedux/configureStore';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Label } from '@fluentui/react/lib/Label';
// Componentes
import Navbar from './ComponentesGenerales/Navbar/Navbar';

// Sitios
const Login = lazy(() => import('./Sitios/Login/Inicio'));
const Dashboard = lazy(() => import('./Sitios/Dashboard/Dashboard'));
//const ListadoFormularios = lazy(() => import('./Sitios/ListadoFormularios/ListadoFormularios'));
//const Formulario = lazy(() => import('./Sitios/Formulario/Formulario'));
const FormularioF1toF4 = lazy(() => import('./Sitios/Formulario/FormularioF1toF4'));
const FormularioF7toF10 = lazy(() => import('./Sitios/Formulario/FormularioF7toF10'));
const FormularioF11toF15 = lazy(() => import('./Sitios/Formulario/FormularioF11toF15'));
const FormularioF16toF21 = lazy(() => import('./Sitios/Formulario/FormularioF16toF21'));
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<>
		<MsalProvider instance={msalInstance}>
			<Provider store={AppStore}>
				<Suspense fallback={
					<div className='ms-Grid-col ms-sm6 ms-xl6'>
						<Label>Cargando Formulario PMGD...</Label>
						<Spinner size={SpinnerSize.large} /></div>}>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<App />}>
								<Route path="" element={<Login />} />
								<Route path="/Dashboard" element={<Navbar />} >
									<Route index element={<Dashboard />} />
									<Route path="NuevoFormulario" element={<FormularioF1toF4 nuevoFormulario={true} />} />
									<Route path="FormularioF1toF4/:Id/:FId" element={<FormularioF1toF4 nuevoFormulario={false} />} />
									<Route path="FormularioF7toF10/:Id/:FId" element={<FormularioF7toF10 nuevoFormulario={false} />} />
									<Route path="FormularioF11toF15/:Id/:FId" element={<FormularioF11toF15 nuevoFormulario={false} />} />
									<Route path="FormularioF16toF21/:Id/:FId" element={<FormularioF16toF21 nuevoFormulario={false} />} />
								</Route>
							</Route>
						</Routes>

					</BrowserRouter>
				</Suspense>

			</Provider>
		</MsalProvider>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
