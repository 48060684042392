import { useNavigate } from "react-router-dom";
import { IButtonStyles, PrimaryButton } from "@fluentui/react";

function Opciones() {

	const redireccionar = useNavigate();
    
    const primaryButtonStyles: IButtonStyles = {

		//   fieldGroup: [{ borderColor: '#ced4da' }]// required && { borderTopColor: props.theme.semanticColors.errorText,},],
		root: { background: '#FFFFFF', borderColor: '#FFFFFF', color: '#686B6E' },
		
	}

    function cerrarSesion() {

        sessionStorage.clear();

        redireccionar('/')
        
    }

    return (
        <div style={{width: "125px"}}>
            <PrimaryButton 
            text="Cerrar sesión"
            styles={primaryButtonStyles}
            onClick={() => cerrarSesion()}/>
        </div>
    )
}

export default Opciones