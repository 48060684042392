import { KnowActions } from './actions';
import { Action, Reducer } from 'redux';
import { IProcesoPMGD } from "../../../Interfaces/IProcesoPMDG";
import { ISolicitanteEmpresa } from "../../../Interfaces/ISolicitanteEmpresa";
import { RESPONSE_GETLISTADO_FORMULARIOS, RESPONSE_GETSOLICITANTE_EMPRESA } from './constants';

export interface IFormularioPMGDState {
	procesoPMDGList: IProcesoPMGD[],
	solictanteEmpresa: ISolicitanteEmpresa[]
	// Title: string | null;
}

export const initialState: IFormularioPMGDState = {
	// Id: null,
	procesoPMDGList: [],
	solictanteEmpresa: []
};

//Reducer determines how the state should change after every action.
export const FormularioReducer: Reducer<IFormularioPMGDState> = (state: IFormularioPMGDState = initialState, incomingAction: Action): IFormularioPMGDState => {
	const action = incomingAction as KnowActions;
	switch (action.type) {
		case RESPONSE_GETLISTADO_FORMULARIOS:
			return { ...state, procesoPMDGList: action.procesoPMGDList }
		// return { ...state, procesosPMGDList: action.procesoPMGDList};
		case RESPONSE_GETSOLICITANTE_EMPRESA:
			return { ...state, solictanteEmpresa: action.solictanteEmpresa }
		default:
			return state;
	}
};