import './App.css';
import { useNavigate, Outlet } from "react-router-dom";
//import { lazy } from 'react';
import { ReactSession } from 'react-client-session';
// Íconos Fluent UI
import { initializeIcons } from '@fluentui/react/lib/Icons';
import {  useEffect } from 'react';
import { ActionListaMaestra } from './GlobalStore/Reducers/ListasMaestrasReducer/actions';
import { useDispatch } from 'react-redux';
// Componentes
import Navbar from './ComponentesGenerales/Navbar/Navbar';
import useLocalStorage from './GlobalStore/SesionLocal/SesionLocal';
//import { ActionLogin } from './GlobalStore/Reducers/LoginReducer/actions';
import { API_LOGIN } from './GlobalStore/Reducers/LoginReducer/constants';



initializeIcons(undefined, { disableWarnings: true });

function App() {

	const redireccionar = useNavigate();  // Función para redireccionar.
	ReactSession.setStoreType("sessionStorage");
	const sesionLocal = useLocalStorage();
	const dispatch = useDispatch();
	useEffect(() => {
		if (sesionLocal.usuario !== undefined) {
			dispatch(ActionListaMaestra.ObtenerListasMaestras());
			dispatch({ type: API_LOGIN, DataUsuario: sesionLocal })
		} else { redireccionar('/') }
	}, []);

	return (
		<>
			<Outlet />
		</>
	);

}

export default App;