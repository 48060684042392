
import * as InformacionUsuario from '../Reducers/LoginReducer/reducer' ;
import * as ListasMaestras from '../Reducers/ListasMaestrasReducer/reducer';
import * as Formulario from '../Reducers/FormularioReducer/reducer';

export interface ApplicationState {
    //Este AppState es de la pantalla principal
    UsuarioPMGD: InformacionUsuario.IUsuarioPMGDState | undefined;
    ListasMaestrasPMGD: ListasMaestras.IListasMaestrasPMGDState | undefined;
    FormularioPMGD: Formulario.IFormularioPMGDState | undefined
}

export const reducers = {

    UsuarioPMGD: InformacionUsuario.InformacionUsuarioReducer,
    ListasMaestrasPMGD: ListasMaestras.ListasMaestrasReducer,
    FormularioPMGD: Formulario.FormularioReducer,

};

export interface AppThunkAction<TAction> {

    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
    
}
